import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  redirect,
} from "react-router-dom";
import api from "../api";
import { ForgetPassword } from "../component/forgetpass";
import { Home } from "../component/home";
import { SignIn } from "../component/signIn";
import { SignUp } from "../component/signup";
import { TwoFA } from "../component/twofa";
import { VerifyEmail } from "../component/verifyemail";
import userAction from "../redux/users/action";
import ProtectedRoutes from "./SecureRoutes";
import AuthProtectedRoutes from "./SecureRoutesAuth";
import VerfiyProtectedRoutes from "./SecureRoutesEmailVerify";

const Router = (props) => {
  const userStore = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const Location = useLocation();
  const authRoutes = ["/signup", "/signin", "/"];

  const dispatch = useDispatch();
  const { setUser } = userAction;

  useEffect(() => {
    if (userStore?.users?.token) {
      axios.defaults.headers.common["Authorization"] = userStore?.users?.token;

      checkSession();
    }
  }, []);

  useEffect(() => {
    if (userStore?.users?.token) {
      axios.defaults.headers.common["Authorization"] = userStore?.users?.token;

      checkSession();
    }
  }, [window.location.pathname]);

  const checkSession = async () => {
    try {
      axios.defaults.headers.common["Authorization"] = userStore?.users?.token;
      let session;

      if (Location.pathname == "/signin") {
        session = await api.checkLoginSessionWithoutFA();
      } else {
        session = await api.checkSession();
      }

      if (!session) {
        dispatch(setUser());
        return false;
      }

      await fetchUser();
      return true;
    } catch (e) {
      dispatch(setUser());
      console.log(e);
      return false;
    }
  };

  const fetchUser = async () => {
    try {
      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);

      dispatch(setUser(response));
    } catch (e) {
      console.log(e);
    }
  };

  // window?.ethereum?.on("accountsChanged", (accounts) => {
  //   window.location.reload();
  // });

  // window?.ethereum?.on("chainChanged", (chainId) => {
  //   window.location.reload();
  // });

  // window?.bitkeep?.ethereum?.on("accountsChanged", (accounts) => {
  //   window.location.reload();
  // });

  // window?.bitkeep?.ethereum?.on("chainChanged", (chainId) => {
  //   window.location.reload();
  // });

  return (
    <>
      <Routes>
        <Route exact={true} path="/signup" element={<SignUp />} />
        <Route exact={true} path="/signin" element={<SignIn />} />
        <Route exact={true} path="/forget-password" element={<ForgetPassword />} />

        <Route element={<AuthProtectedRoutes />}>
          <Route exact={true} path="/twofa-verify" element={<TwoFA />} />
        </Route>

        <Route element={<VerfiyProtectedRoutes />}>
        <Route exact={true} path="/verify-email" element={<VerifyEmail />} />
        </Route>

        <Route element={<ProtectedRoutes />}>
          <Route
            exact={true}
            path="/home"
            element={<Home checkSession={checkSession} />}
          />
          <Route
            path=":page/*"
            element={<Home checkSession={checkSession} />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    </>
  );
};

export default Router;
