import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment";
import AddIcon from "../images/add.png";
import Cross from "../images/cross.png";
import Edit from "../images/editIcon.png";
import ibtisam from "../images/banner.jpg";
import b from "../images/ibtisam.jpg";
import arrow from "../images/arrow.svg";
import arrowSelected from "../images/icon-arrow-selected.png";
import { Modal } from "./modal";
import { useAlert } from "react-alert";
import { Lightbox } from "react-modal-image";
import ShowMoreText from "react-show-more-text";

export function Signals(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [addSignal, setAddSignal] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [mode, setMode] = useState(0);
  const [reactions, setReactions] = useState([]);
  const [reactionModal, setreactionModal] = useState(false);
  const [seeReactionsModal, setSeeRections] = useState(false);
  const [reactamount, setreactamount] = useState(0);
  const [reactStatus, setreactstatus] = useState(0);
  const [imageopen, setImageOpen] = useState(false);
  const [imageViewable, setImageToView] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("");
  const [inputData, setInputData] = useState({
    signal: "",
    img: "",
    format: "",
  });
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    if (userStore?.users?.payment?.paymentCategory == "0") {
      alert.show("In active members cannot watch Signals.");
      navigate("/payment");
    }

    // fetchData();
    // fetchReactionsSignal();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  // const fetchReactionsSignal = async () => {
  //   try {
  //     setLoader(true);
  //     let response;

  //     const payload = {
  //       _id: userStore.users.user._id,
  //       user: userStore.users.user._id,
  //     };
  //     response = await api.getReactionSignal(payload);

  //     setReactions(response);
  //     setLoader(false);
  //   } catch (e) {
  //     console.log(e);
  //     setLoader(false);
  //   }
  // };

  const fetchAllReactions = async (item) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
        signal: item._id
      };
      response = await api.getAllReactions(payload);
      console.log(response);
      setReactions(response);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchData = async (uid) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        page: page,
        _id: userStore.users.user._id,
      };
      response = await api.signalGet(payload);

      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.data]);
      console.log(response);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        _id: userStore.users.user._id,
        signal: inputData.signal,
        imageTitle: inputData.img || "",
        imageFormat: inputData.format || "",
      };
      const signalResponse = await api.signalCreate(payload);
      if(signalResponse) {
        let tempData = data
        tempData.unshift(payload)

        alert.show("Signal created successfully");
        setInputData({
          signal: "",
          img: "",
          format: "",
        });
        setSelectedImg(null);
        setAddSignal(false);
        // await fetchData();
        setLoader(false);
      }
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const edit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        signal: inputData.signal,
        _id: userStore.users.user._id,
        imageTitle: inputData.img,
        imageFormat: inputData.format,
        signalId: selectedSignal?._id,
      };
      const signalResponse = await api.signalEdit(payload);
      let tempData = [...data]
      if(signalResponse){
        tempData.splice(currentIndex,1,payload)
        setData(tempData)
        alert.show("Signal editted successfully");
        setInputData({
          signal: "",
          img: "",
          format: "",
        });
        setSelectedImg(null);
        // await fetchData();
        setAddSignal(false);
        setLoader(false);
      }
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const onDelete = async (item,index) => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        signalId: item?._id,
      };
      const signalResponse = await api.signalDelete(payload);
      let tempData= [...data]
      if(signalResponse){
        tempData.splice(index,1);
        setData(tempData)

        alert.show("Signal deleted successfully");
        // await fetchData();
        setLoader(false);
      }
    } catch (e) {
      alert.show("Signal deleted un-successfully");
      setLoader(false);
    }
  };

  const validator = () => {
    if (!inputData.signal) {
      return "Signal is required";
    }
    // if (!inputData.img) {
    //   return "Image is required";
    // }

    return false;
  };

  const dateFormat = (datePram) => {
    const date = new Date(datePram);

    // return date.toLocaleString();
    return moment(date).format("MMMM Do YYYY, h:mm:ss a");
  };

  const onDataChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const onFileChange = async (e) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);

      setSelectedImg(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        img: uploadRes[0],
        format: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert.show("File upload failed");
      setLoader(false);
    }
  };

  const close = () => {
    setMode(0);
    setInputData({
      signal: "",
      img: "",
      format: "",
    });
    setSelectedImg(null);
    setAddSignal(false);
    setreactionModal(false);
    setSelectedSignal(null)
    setSeeRections(false);
  };

  const editOpen = (item,index) => {
    setMode(1);
    setSelectedSignal(item);
    setInputData({
      signal: item?.signal,
      format: item?.imageFormat,
      img: item?.imageTitle,
    });
    setSelectedImg(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
    );
    setAddSignal(true);
    setCurrentIndex(index)
  };

  const daysDifference = (item) => {
    const date1 = new Date();
    const date2 = new Date(item.createdAt);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays - 1;
  };

  const open = () => {
    setMode(0);
    setAddSignal(true);
  };

  const react = async () => {
    try {
      const signal = selectedSignal;
      const status = reactStatus;
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      if (!reactamount) {
        alert.show("Percentage is required")
        return
      }

      if (isNaN(reactamount)) {
        alert.show("Percent must be a number")
        return
      }

      if (reactamount <= 0) {
        alert.show("Percent must be greater than 0")
        return
      }

      if (reactamount  > 100) {
        alert.show("Percent must be lesser than 100")
        return
      }

      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        react: status,
        reactAmount: reactamount,
        signal: signal?._id,
      };
      const reactResponse = await api.reactCreate(payload);
      if(reactResponse == "Reacted!"){
        let tempData = data
        console.log(tempData[currentItem].reactions);
      for (let i = 0; i < tempData[currentItem].reactions.length; i++) {
        if(tempData[currentItem].reactions[i].user == userStore?.users?.user?._id){
          tempData[currentItem].reactions[i].react = status;
          console.log(tempData);
          setData(tempData)
          alert.show("Reacted successfully");
          setreactionModal(false);
          // await fetchData();
          setLoader(false);
          return
        }
      }
      console.log(tempData);
      tempData[currentItem].reactions.push(payload)
        // let tempData = data
      }
      alert.show("Reacted successfully");
      setreactionModal(false);
      // await fetchData();
      setLoader(false);
    } catch (e) {
      alert.show("React un-successfully");
      setLoader(false);
    }
  };

  const setReactAmountChange = (e) => {
    setreactamount(e.target.value);
  };

  const openreactmodal = (item, status, index) => {
    setSelectedSignal(item);
    setreactstatus(status);
    setreactionModal(true);
    setCurrentItem(index)
  };

  const closeLightbox = () => {
    setImageToView(null);
    setImageOpen(false);
  };

  const openImg = (item) => {
    setImageToView(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
    );
    setImageOpen(true);
  };

  const countProfit = (item) => {
    let count = 0;

    for (let i = 0; i < item.reactions.length; i++) {
      if (item.reactions[i].react == "1") {
        count += 1;
      }
    }

    if (!item.reactions.length) {
      return 0;
    }

    return count;
    // return (count / item.reactions.length) * 100;
  };

  const countLoss = (item) => {
    let count = 0;

    for (let i = 0; i < item.reactions.length; i++) {
      if (item.reactions[i].react == "2") {
        count += 1;
      }
    }

    if (!item.reactions.length) {
      return 0;
    }

    return count;
    // return (count / item.reactions.length) * 100;
  };

  const countProfitUser = (item) => {
    let count = 0;
    if(item?.reactions){
      for (let i = 0; i < item?.reactions.length; i++) {
        if (item.reactions[i].react == "1") {
          count += 1;
        }
      }
    }  

    return count;
  };

  const countLossUser = (item) => {
    let count = 0;
    if(item?.reactions){
      for (let i = 0; i < item.reactions.length; i++) {
        if (item.reactions[i].react == "2") {
          count += 1;
        }
      }
    }

    return count;
  };

  const checkIsSelected = (item, status) => {
    for (let i = 0; i < item.reactions.length; i++) {
      if (
        item.reactions[i].user == userStore?.users?.user?._id &&
        item.reactions[i].react == status
      ) {
        return true;
      }
    }

    return false;
  };

  const unreactSignal = async (item, index) => {
    try {
      setLoader(true);
      console.log(item);

      const payload = {
        _id: userStore?.users?.user?._id,
        sid: item._id,
      };
      const unreact = await api.deletereactionOfSignal(payload);
      if(unreact == "Signal reaction deleted"){
        let tempData = [...data]
          console.log(tempData[index].reactions);
        for (let i = 0; i < tempData[index].reactions.length; i++) {
          if(tempData[index].reactions[i].user == userStore?.users?.user?._id){
            tempData[index].reactions.splice(i, 1);
          }
        }
        console.log('====================================');
        console.log(tempData);
        setData(tempData)
        console.log('====================================');
      }
      // await fetchData();
      setLoader(false);
      alert.show("Successfully un-reacted to signal");
    } catch (e) {
      console.log(e);
      alert.show("Error while un-reacting");
      setLoader(false);
    }
  };

  const seeReactions = (item) => {
    fetchAllReactions(item)
    setSeeRections(true);
  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };
  
  return (
    <div className="container signalsMain" style={{background:"transparent"}}>
      {/* <div className="signalsHeading">Signals</div> */}
      {(userStore.users.user.permissions.includes(1) ||
        userStore.users.user.permissions.includes(6)) && (
        <div className="addButton">
          <img onClick={() => open()} src={AddIcon} alt="add" />
        </div>
      )}
      <div className="row signals">
        {data?.map((item,key) => (
          <div className="col-12 ">
            <div className=" signalsCont">
              <span className="postedBy">
                <div className="avatar-cont-gui-sig">
                  <img src={b} />
                </div>
                Tenup Team
              </span>
              <div className="postMa">
                <TextPost item={item} key={key} />
              </div>
              <div className=" postImage">
                {item?.imageTitle &&
                <img
                  onClick={() => openImg(item)}
                  class="card-img-top postImagesrc"
                  src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`}
                  alt="Card image cap"
                />
                }
              </div>
              <div className="bottom-cont-posts">
                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(6)) && (
                  <span className="icons">
                    <img onClick={() => onDelete(item,key)} src={Cross} alt="add" />
                    <img onClick={() => editOpen(item,key)} src={Edit} alt="add" />
                    <div className="reactions-icon-label-cont">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title={countProfitUser(item)}
                        class="btn bgSuccess button-pr-los"
                      >
                        {" "} 
                        <img src={arrow} className="uparrow-signal" />
                        Profit
                      </button>
                    </div>
                    <div className="reactions-icon-label-cont">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title={countLossUser(item)}
                        class="btn bgLoss button-pr-los"
                      >
                        {" "}
                        <img src={arrow} className="downarrow-signal" />
                        Loss
                      </button>
                    </div>
                  </span>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <span className="icons">
                    <div className="reactions-icon-label-cont">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title={countProfit(item)}
                        class={checkIsSelected(item, 1)?
                          "btn bgSuccess button-pr-los active"
                          :
                          "btn bgSuccess button-pr-los"
                        } 
                        onClick={() =>
                          checkIsSelected(item, 1)
                            ? unreactSignal(item,key)
                            : openreactmodal(item, 1, key)
                        }
                      >
                        {" "}
                        <img
                          src={arrow}
                          className="uparrow-signal"
                        />{" "}
                        Profit
                      </button>
                    </div>
                    <div className="reactions-icon-label-cont">
                      <button
                        data-toggle="tooltip"
                        data-placement="top"
                        title={countLoss(item)}
                        class={checkIsSelected(item, 2)?
                          "btn bgLoss button-pr-los active"
                          :
                          "btn bgLoss button-pr-los"
                        }
                        
                        
                        onClick={() =>
                          checkIsSelected(item, 2)
                            ? unreactSignal(item,key)
                            : openreactmodal(item, 2, key)
                        }
                      >
                        <img
                          src={arrow}
                          className="downarrow-signal"
                        />{" "}
                        Loss
                      </button>
                    </div>
                  </span>
                )}
                {}
                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(6)) && (
                  <button onClick={()=> seeReactions(item)} class="btn bg-dark text-light">
                    View
                  </button>
                )}
                <div className="dateTool time">
                  {moment(item?.createdAt).fromNow()}
                  <div className="fullDate">{dateFormat(item?.createdAt)}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isMoreAvailable && <div className="btnShowMoreCont"><button className="showMoreButton" onClick={showMore}>Show more</button></div>}
      {!data.length && (
        <p className="text-center">No data available to show.</p>
      )}
      <Loader loader={loader} />
      <Modal
        text={mode == 0 ? "Create a Signal Post" : "Edit a Signal Post"}
        visible={addSignal}
        onChange={onDataChange}
        onFileChange={onFileChange}
        selectedImg={selectedImg}
        signal={inputData.signal}
        signalAndCrypto={true}
        OnClose={close}
        mode={mode}
        onClick={mode == 0 ? onSubmit : edit}
      />
      <Modal
        text={"Reactions of Signal"}
        visible={seeReactionsModal}
        reactionView={seeReactionsModal}
        reactionsData={reactions}
        OnClose={close}
      />
      <Modal
        text={"React on Signal"}
        visible={reactionModal}
        signalreaction={reactionModal}
        onChange={setReactAmountChange}
        OnClose={close}
        onClick={react}
      />
      {imageopen && <Lightbox medium={imageViewable} onClose={closeLightbox} />}
    </div>
  );
}

const TextPost = ({item,key}) => {
  const [readMore, setReadMore] = useState(false);

  // const handleShowMore = (key) => {
  //   var post = document.getElementById(`post${key}`)
  //   post.style.overflow="unset"
  //   post.style.maxHeight="unset"
  // } 
  // const ShowMoreHeight = (key) => {
  //   var post = document.getElementById(`post${key}`)
  //   console.log(post.innerText.includes('\n')); 
  //   console.log(post.getClientRects().length); 
  // }
    // const claimToEth = () =>{
    //   var temp = "This is a string\n sasa";
    //   var count = (temp.match(/\n/g) || []).length;
    //   console.log(count);
    // }
    useEffect(()=>{
      console.log( (item?.signal.match(/\n/g) || []).length )

      // console.log();

    },[])
  return (
    <>  
      <p className="post" id={`post${key}`} >
        {/* {readMore ? item?.signal : `${item?.signal.substring(0, 80)}...`} */}
        {readMore ? item?.signal :
        (item?.signal.match(/\n/g) || []).length > 2
        ?
         item?.signal.substring(0, item?.signal.indexOf("\n", item?.signal.indexOf("\n") + 1))
         :
         item?.signal
        }
      </p>
      {(item?.signal.match(/\n/g) || []).length > 2 &&
      <div className="showMoreB" onClick={() => setReadMore(!readMore)}>{readMore ? "show less" : "  read more"}</div>
      }
    </>

  )
}
