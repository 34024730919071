import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment";
import AddIcon from "../images/add.png";
import Cross from "../images/cross.png";
import Play from "../images/play.png";
import Edit from "../images/editIcon.png";
import ibtisam from "../images/banner.jpg";
import b from "../images/ibtisam.jpg";
import { Modal } from "./modal";
import { useAlert } from "react-alert";
import constants from "../helpers/constants";
import { Lightbox } from "react-modal-image";

export function Guides(props) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [addSignal, setAddSignal] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const [selectedImg, setSelectedImg] = useState(null);
  const [videoModal, setVideoModel] = useState(false);
  const [link, setLink] = useState(null);
  const [title, setTitle] = useState(null);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [cateogry, setCateogry] = useState([]);
  const [mode, setMode] = useState(0);
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [inputData, setInputData] = useState({
    img: "",
    format: "",
    category: "",
    guide: "",
    video: "",
  });
  const [imageopen, setImageOpen] = useState(false);
  const [imageViewable, setImageToView] = useState(false);

  useEffect(() => {
    if (userStore?.users?.payment?.paymentCategory == "0") {
      alert.show("In active members cannot watch Guides.");
      navigate("/payment");
    }

    // fetchData();
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchCategory = async (uid) => {
    try {
      setLoader(true);
      let response;
      const payload = {
        _id: userStore.users.user._id,
      };
      response = await api.guideCategory(payload);

      setCateogry(response);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchData = async (uid) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
        page: page,
      };
      response = await api.getGuide(payload);

      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.data]);
      setFilteredData([...filteredData, ...response.data]);

      console.log(response);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        _id: userStore.users.user._id,
        title: inputData.guide,
        category: inputData.category,
        video: inputData.video,
        imageTitle: inputData.img,
        imageFormat: inputData.format,
      };
      const resp = await api.createGuide(payload);

      console.log(payload);
      alert.show("Guide created successfully");
      setInputData({
        guide: "",
        category: "",
        img: "",
        format: "",
        video: "",
      });
      setSelectedImg(null);
      setAddSignal(false);
      await fetchData();
      setLoader(false);
      console.log(inputData);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const edit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        guideId: selectedSignal?._id,
        _id: userStore.users.user._id,
        title: inputData.guide,
        category: inputData.category,
        video: inputData.video,
      };

      if (inputData.img) {
        payload.imageTitle = inputData.img;
        payload.imageFormat = inputData.format;
      }

      const response = await api.editGuide(payload);

      alert.show("Guide editted successfully");
      setInputData({
        guide: "",
        category: "",
        img: "",
        format: "",
        video: "",
      });
      setSelectedImg(null);
      await fetchData();
      setAddSignal(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const onDelete = async (item) => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        guideId: item?._id,
      };
      const resp = await api.deleteGuide(payload);

      alert.show("Guide deleted successfully");
      await fetchData();
      setLoader(false);
    } catch (e) {
      alert.show("Signal deleted un-successfully");
      setLoader(false);
    }
  };

  const validator = () => {
    if (!inputData.guide) {
      return "Guide title is required";
    }
    // if (!inputData.img) {
    //   return "Image is required";
    // }
    if (!inputData.category) {
      return "Category is required";
    }
    if (!inputData.video) {
      return "Video Link is required";
    }
    if (inputData.video && !constants.regexURL.test(inputData.video)) {
      return "Video link is not valid";
    }

    return false;
  };

  const onDataChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const onFileChange = async (e) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);

      setSelectedImg(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        img: uploadRes[0],
        format: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert.show("File upload failed");
      setLoader(false);
    }
  };

  const close = () => {
    setMode(0);
    setInputData({
      signal: "",
      img: "",
      format: "",
    });
    setSelectedImg(null);
    setAddSignal(false);
  };

  const editOpen = (item) => {
    setMode(1);
    setSelectedSignal(item);
    setInputData({
      guide: item?.title,
      format: item?.imageFormat,
      img: item?.imageTitle,
      category: item?.category,
      video: item?.video,
    });
    setSelectedImg(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
    );
    setAddSignal(true);
  };

  const open = () => {
    setMode(0);
    setAddSignal(true);
  };

  const filterCategory = (category) => {
    const dataFilter = data;

    if (category === "All") {
      setFilteredData(data);
      return;
    } else {
      setFilteredData(
        dataFilter.filter((item) => item.category === category._id)
      );
    }
  };

  const playVideo = (item) => {
    setLink(item?.video);
    setTitle(item?.title);
    setVideoModel(true);
  };

  const closeVideo = () => {
    setVideoModel(false);
    setLink(null);
    setTitle(null);
  };

  const closeLightbox = () => {
    setImageToView(null);
    setImageOpen(false);
  };

  const openImg = (item, defaultIt) => {
    if (defaultIt) {
      setImageToView(ibtisam);
    } else {
      setImageToView(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
      );
    }
    setImageOpen(true);
  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container signalsMain">
      {/* <div className="signalsHeading">Guides / Resources</div> */}
      <div className="row as-fs">
        <div className="col">Categories: </div>
        <div className="col">
          <button
            onClick={(e) => filterCategory("All")}
            className="categoryButton"
          >
            All
          </button>
        </div>
        {cateogry?.map((item) => (
          <div className="col">
            <button
              onClick={(e) => filterCategory(item)}
              className="categoryButton"
            >
              {item.name}
            </button>
          </div>
        ))}
      </div>
      <div className="row row-guides">
        {filteredData?.map((item) => (
          <div className="col-12 col-md-3">
            <div class="card card-width-guides">
              <div className="GuidesImgCont">
                {item?.imageTitle && (
                  <img
                    onClick={() => openImg(item)}
                    src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`}
                    class="card-img-top "
                    alt="..."
                  />
                )}
                {!item?.imageTitle && (
                  <img
                    src={ibtisam}
                    onClick={() => openImg(item, true)}
                    class="card-img-top "
                    alt="..."
                  />
                )}
                {(userStore.users.user.permissions.includes(1) ||
                  userStore.users.user.permissions.includes(8)) && (
                  <img
                    className="deleteIcoGuides"
                    onClick={() => onDelete(item)}
                    src={Cross}
                    alt="add"
                  />
                )}
                {(userStore.users.user.permissions.includes(1) ||
                  userStore.users.user.permissions.includes(8)) && (
                  <img
                    className="editIcoGuides"
                    onClick={() => editOpen(item)}
                    src={Edit}
                    alt="add"
                  />
                )}
              </div>
              <div class="card-body">
                <p class="card-text card-guides-para">
                  {item?.title}
                  <img
                    className="playIcon"
                    onClick={(e) => playVideo(item)}
                    src={Play}
                  />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {(userStore.users.user.permissions.includes(1) ||
        userStore.users.user.permissions.includes(8)) && (
        <div className="addButton">
          <img onClick={() => open()} src={AddIcon} alt="add" />
        </div>
      )}
      {isMoreAvailable && (
        <button className="showMoreButton" onClick={showMore}>
          Show more
        </button>
      )}
      {!filteredData.length && (
        <p className="text-center">No data available to show.</p>
      )}

      <Loader loader={loader} />
      <Modal
        text={mode == 0 ? "Create a Guide Post" : "Edit a Guide Post"}
        visible={addSignal}
        guides={true}
        guidesCategory={cateogry}
        onChange={onDataChange}
        onFileChange={onFileChange}
        selectedImg={selectedImg}
        OnClose={close}
        mode={mode}
        onClick={mode == 0 ? onSubmit : edit}
        inputData={inputData}
      />
      <Modal
        videoTitle={title}
        // text={"Video"}
        visible={videoModal}
        videoLinkGuide={link}
        OnClose={closeVideo}
      />
      {imageopen && <Lightbox medium={imageViewable} onClose={closeLightbox} />}
    </div>
  );
}
