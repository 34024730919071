import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.svg";
import signupCover from "../images/signupCover.png";
import signElement from "../images/signElement.png";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import api from "../api";
import { Loader } from "./loader";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import helpers from "../helpers/helpers";
import { useSelector } from "react-redux";

export function SignUp(params) {
  const navigate = useNavigate();
  const [modalLogin, setModalLogin] = useState(false);
  const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (userStore?.users?.token) {
        if (userStore.users.user.profileType == "admin") {
          navigate("/payment-admin");
        }
        else {
          navigate("/payment");
        }
    }
  }, []);

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loader, setLoader] = useState(false);

  const register = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        email: data.email,
        password: data.password,
        name: data.firstName + " " + data.lastName,
        profileType: "Tenup Member",
        phone: data.phone
      };
      const registerationResponse = await api.register(payload);

      const createPaymentPayload = {
        _id: registerationResponse._id
      }
      const createPayment = await api.createPayment(createPaymentPayload)

      console.log(createPayment);
      console.log(registerationResponse);

      alert.show("User registered successfully");
      setLoader(false);
      setModalLogin(true);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.firstName) {
      return "First name is required";
    }
    if (!data.lastName) {
      return "Last name is required";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Phone is not correctly formatted";
    }
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password.length < 8) {
      return "Password must be 8 characters long";
    }
    if (!data.confirmPassword) {
      return "Confirm password is required";
    }
    if (data.password !== data.confirmPassword) {
      return "Password and Confirm password not matched";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="signupPage">
      <img className="element" src={signElement} alt="signElement" />
      <div className="logoDiv">
        <img src={logo} alt="logo" />
      </div>
      <div className="sigupRow">
        <div className="container">
          <div className="row jc-sb ai-center">
            <div className="col-lg-6">
              <img
                className="img-fluid signupCover"
                src={signupCover}
                alt="cover"
              />
            </div>
            <div className="col-lg-5">
              <div className="signForm">
                <h2>Hello,</h2>
                <h2 className="mb-4">Welcome to TenUP</h2>
                <form>
                  <div className="mb-3 d-flex">
                    <TextField
                      onChange={onTextChange}
                      name="firstName"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                    />
                    <TextField
                      onChange={onTextChange}
                      name="lastName"
                      className="form-control"
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="email"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="phone"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      type={"password"}
                      onChange={onTextChange}
                      name="password"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      type={"password"}
                      onChange={onTextChange}
                      name="confirmPassword"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Confirm Password"
                      variant="outlined"
                    />
                  </div>

                  {/* <div className="my-4 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Keep me logged in</label>
                                </div> */}
                  <button
                    type="submit"
                    onClick={register}
                    className="btn signBtn"
                  >
                    Submit
                  </button>

                  <div className="alreadySign">
                    <p>
                      Already have account? <Link to="/signin">Sign-In</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        text={"Your account was created, Login to continue!"}
        visible={modalLogin}
        iscontinue={true}
        isClose={false}
        onContinue={() => navigate("/signin")}
        OnClose={() => setModalLogin(false)}
      />
      <Loader loader={loader} />
    </div>
  );
}
