import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment";
import AddIcon from "../images/add.png";
import Cross from "../images/cross.png";
import Edit from "../images/editIcon.png";
import ibtisam from "../images/banner.jpg";
import b from "../images/ibtisam.jpg";
import { Modal } from "./modal";
import { useAlert } from "react-alert";
import { Lightbox } from "react-modal-image";
import likeSelected from "../images/dislike.png";
import like from "../images/dislike-selected.png";
import dislikeSelected from "../images/like.png";
import dislike from "../images/like-selected.png";
import heartSelected from "../images/heart.png";
import heart from "../images/heart-selected.png";

export function CryptoUpdates(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [addSignal, setAddSignal] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [reactions, setReactions] = useState([]);
  const [seeReactionsModal, setSeeRections] = useState(false);
  const [imageopen, setImageOpen] = useState(false);
  const [imageViewable, setImageToView] = useState(false);
  const [mode, setMode] = useState(0);
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [currentIndex, setCurrentIndex] = useState("");
  const [inputData, setInputData] = useState({
    title: "",
    img: "",
    format: "",
  });

  useEffect(() => {
    if (userStore?.users?.payment?.paymentCategory == "0") {
      alert.show("In active members cannot watch Crypto Updates.");
      navigate("/payment");
    }

    // fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchAllReactions = async (item) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
        cryptoUpdateId: item._id,
      };
      response = await api.getAllReactionsCrypto(payload);

      setReactions(response);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchData = async (uid) => {
    try {
      setLoader(true);
      let response;
      const payload = {
        _id: userStore.users.user._id,
        page: page,
      };
      response = await api.CryptoUpdateGet(payload);
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.data]);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        _id: userStore.users.user._id,
        title: inputData.title,
        imageTitle: inputData.img || "",
        imageFormat: inputData.format || "",
      };
      const resp = await api.CryptoUpdateCreate(payload);
      console.log(resp);
      console.log(payload);
      if(resp == "Update created"){
        let tempData = data
        tempData.unshift(payload)
        // setData(tempData)
        alert.show("Update created successfully");
        setInputData({
          title: "",
          img: "",
          format: "",
        });
        setSelectedImg(null);
        setAddSignal(false);
        // await fetchData();
        setLoader(false);
      }

    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const edit = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validators = await validator();

      if (validators) {
        throw new Error(validators);
      }

      const payload = {
        title: inputData.title,
        _id: userStore.users.user._id,
        imageTitle: inputData.img,
        imageFormat: inputData.format,
        updateId: selectedSignal?._id,
      };
      const resp = await api.CryptoUpdateEdit(payload);
      let tempData = [...data]
      if(resp){
        tempData.splice(currentIndex,1,payload)
        setData(tempData)
        alert.show("Update editted successfully");
        setInputData({
          title: "",
          img: "",
          format: "",
        });
        setSelectedImg(null);
        setAddSignal(false);
        setLoader(false);
      }
      // await fetchData();
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const onDelete = async (item, index) => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        updateId: item?._id,
      };
      const resp = await api.CryptoUpdateDelete(payload);
      let tempData= [...data]
      console.log(index);
      console.log(resp);
      if(resp){
          tempData.splice(index,1);
          setData(tempData)
          setLoader(false);
      
        alert.show("Update deleted successfully");
      }

      // await fetchData();
    } catch (e) {
      alert.show("Update deleted un-successfully");
      setLoader(false);
    }
  };

  const validator = () => {
    if (!inputData.title) {
      return "Signal is required";
    }
    // if (!inputData.img) {
    //   return "Image is required";
    // }

    return false;
  };

  const onDataChange = (e) => {
    setInputData({ ...inputData, ["title"]: e.target.value });
  };

  const onFileChange = async (e) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);

      setSelectedImg(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        img: uploadRes[0],
        format: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert.show("File upload failed");
      setLoader(false);
    }
  };

  const close = () => {
    setMode(0);
    setInputData({
      title: "",
      img: "",
      format: "",
    });
    setSelectedImg(null);
    setAddSignal(false);
    setSeeRections(false);
  };

  const editOpen = (item,index) => {
    setMode(1);
    setSelectedSignal(item);
    setInputData({
      title: item?.title,
      format: item?.imageFormat,
      img: item?.imageTitle,
    });
    setSelectedImg(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
    );
    setAddSignal(true);
    setCurrentIndex(index)
  };

  const daysDifference = (item) => {
    const date1 = new Date();
    const date2 = new Date(item.createdAt);

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // console.log(diffDays);
    return diffDays - 1;
  };

  const open = () => {
    setMode(0);
    setAddSignal(true);
  };

  const closeLightbox = () => {
    setImageToView(null);
    setImageOpen(false);
  };

  const openImg = (item) => {
    setImageToView(
      `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`
    );
    setImageOpen(true);
  };

  const countLiked = (item) => {
    let count = 0;
    if(item?.reactions){
      for (let i = 0; i < item?.reactions.length; i++) {
        if (item.reactions[i].react == "1") {
          count += 1;
        }
      }
    }

    return count;
  };

  const countDisliked = (item) => {
    let count = 0;
    if(item.reactions){
      for (let i = 0; i < item?.reactions.length; i++) {
        if (item.reactions[i].react == "2") {
          count += 1;
        }
      }
    }

    return count;
  };

  const countHeart = (item) => {
    let count = 0;
    if(item?.reactions){
      for (let i = 0; i < item.reactions.length; i++) {
        if (item.reactions[i].react == "3") {
          count += 1;
        }
      }
    }

    return count;
  };

  const react = async (item, react, currentItem) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      console.log(item);

      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        react: react,
        cryptoUpdateId: item?._id,
      };
      const reactResponse = await api.reactCreateCrypto(payload);

      if(reactResponse == "Reacted!"){
        let tempData = data
        console.log(tempData[currentItem].reactions);
      for (let i = 0; i < tempData[currentItem].reactions.length; i++) {
        if(tempData[currentItem].reactions[i].user == userStore?.users?.user?._id){
          tempData[currentItem].reactions[i].react = react;
          console.log(tempData);
          setData(tempData)
          alert.show("Reacted successfully");
          // await fetchData();
          setLoader(false);
          return
        }
      }
      console.log(tempData);
      tempData[currentItem].reactions.push(payload)
        // let tempData = data
      }

      console.log('====================================');
      console.log(reactResponse);
      console.log(data);
      console.log('====================================');
      alert.show("Reacted successfully");
      // await fetchData();
      setLoader(false);
    } catch (e) {
      alert.show("React un-successfully");
      setLoader(false);
    }
  };

  const unreactSignal = async (item, index) => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore?.users?.user?._id,
        cryptoUpdateId: item._id,
      };
      const unreact = await api.deletereactionOfCrypto(payload);
      console.log('====================================');
      console.log(unreact );
      console.log('====================================');

      if(unreact == "Reaction deleted"){
        let tempData = [...data]
          console.log(tempData[index].reactions);
        for (let i = 0; i < tempData[index].reactions.length; i++) {
          if(tempData[index].reactions[i].user == userStore?.users?.user?._id){
            tempData[index].reactions.splice(i, 1);
          }
        }
        console.log('====================================');
        console.log(tempData);
        setData(tempData)
        console.log('====================================');
      }

      // await fetchData();
      setLoader(false);
      alert.show("Successfully un-reacted to update of crypto");
    } catch (e) {
      console.log(e);
      alert.show("Error while un-reacting");
      setLoader(false);
    }
  };

  const checkIsSelected = (item, status) => {
    for (let i = 0; i < item.reactions.length; i++) {
      if (
        item.reactions[i].user == userStore?.users?.user?._id &&
        item.reactions[i].react == status
      ) {
        return true;
      }
    }

    return false;
  };

  const seeReactions = (item) => {
    fetchAllReactions(item);
    setSeeRections(true);
  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };
  
  const dateFormat = (datePram) => {
    const date = new Date(datePram);
    return moment(date).format('MMM DD YYYY, h:mm:ss a')
    // return date.toLocaleString();
  };
  return (
    <div className="container signalsMain" style={{background:"transparent"}}>
      {/* <div className="signalsHeading">Crypto Updates</div> */}
      {(userStore.users.user.permissions.includes(1) ||
        userStore.users.user.permissions.includes(7)) && (
        <div className="addButton">
          <img onClick={() => open()} src={AddIcon} alt="add" />
        </div>
      )}
      <div className="row signals">
        {data?.map((item,key) => (
          <div className="col-12">
            <div className=" signalsCont">
              <span className="postedBy">
                <div className="avatar-cont-gui-sig">
                  <img src={b} />
                </div>
                Tenup Team
              </span>
              <span className="post">{item?.title}</span>
              <div className=" postImage">
                {item?.imageTitle &&
                <img
                  onClick={() => openImg(item)}
                  class="card-img-top postImagesrc"
                  src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item?.imageTitle}.${item?.imageFormat}`}
                  alt="Card image cap"
                />
                }
              </div>
              <div className="bottom-cont-posts">
                {(userStore.users.user.permissions.includes(1) ||
                  userStore.users.user.permissions.includes(7)) && (
                  <span className="icons">
                    <img onClick={() => onDelete(item,key)} src={Cross} alt="add" />
                    <img onClick={() => editOpen(item,key)} src={Edit} alt="add" />
                  </span>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countLiked(item)}
                      className="reactions-icon-label-cont"
                    >
                      {" "}
                      <img
                        onClick={() =>
                          checkIsSelected(item, 1)
                            ? unreactSignal(item, key)
                            : react(item, 1, key)
                        }
                        src={!checkIsSelected(item, 1) ? like : likeSelected}
                        className="uparrow-signal"
                      />
                    </div>
                  </span>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countDisliked(item)}
                      className="reactions-icon-label-cont"
                    >
                      {" "}
                      <img
                        onClick={() =>
                          checkIsSelected(item, 2)
                            ? unreactSignal(item,key)
                            : react(item, 2,key)
                        }
                        src={
                          !checkIsSelected(item, 2) ? dislike : dislikeSelected
                        }
                        className="uparrow-signal"
                      />
                    </div>
                  </span>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countHeart(item)}
                      className="reactions-icon-label-cont"
                    >
                      {" "}
                      <img
                        onClick={() =>
                          checkIsSelected(item, 3)
                            ? unreactSignal(item,key)
                            : react(item, 3,key)
                        }
                        src={!checkIsSelected(item, 3) ? heart : heartSelected}
                        className="downarrow-signal"
                      />
                    </div>
                  </span>
                )}
                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(7)) && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countLiked(item)}
                      className="reactions-icon-label-cont"
                    >
                      {" "}
                      <img src={likeSelected} className="uparrow-signal" />
                    </div>
                  </span>
                )}

                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(7)) && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countDisliked(item)}
                      className="reactions-icon-label-cont"
                    >
                      {" "}
                      <img src={dislikeSelected} className="uparrow-signal" />
                    </div>
                  </span>
                )}

                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(7)) && (
                  <span className="icons">
                    <div
                      data-toggle="tooltip"
                      data-placement="top"
                      title={countHeart(item)}
                      className="reactions-icon-label-cont"
                    >
                      <img src={heartSelected} className="downarrow-signal" />
                    </div>
                  </span>
                )}
                {(userStore?.users?.user?.permissions.includes(1) ||
                  userStore?.users?.user?.permissions.includes(7)) && (
                  <button
                    onClick={() => seeReactions(item)}
                    class="btn bg-dark text-light"
                  >
                    View
                  </button>
                )}
                <div className="dateTool time">
                  {moment(item?.createdAt).fromNow()}
                  <div className="fullDate">{dateFormat(item?.createdAt)}</div>
                </div>
              
              </div>
            </div>
          </div>
        ))}
      </div>
      {isMoreAvailable && <div className="btnShowMoreCont"><button className="showMoreButton" onClick={showMore}>Show more</button></div>}
      {!data.length && (
        <p className="text-center">No data available to show.</p>
      )}
      <Loader loader={loader} />
      <Modal
        text={
          mode == 0
            ? "Create a Crypto Update Post"
            : "Edit a Crypto Update Post"
        }
        visible={addSignal}
        onChange={onDataChange}
        onFileChange={onFileChange}
        selectedImg={selectedImg}
        signal={inputData.title}
        signalAndCrypto={true}
        OnClose={close}
        mode={mode}
        onClick={mode == 0 ? onSubmit : edit}
      />
      <Modal
        text={"Reactions of Crypto Updates"}
        visible={seeReactionsModal}
        reactionView={seeReactionsModal}
        reactionsData={reactions}
        OnClose={close}
        isCrypto={true}
      />
      {imageopen && <Lightbox medium={imageViewable} onClose={closeLightbox} />}
    </div>
  );
}
