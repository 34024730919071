import axios from "../helpers/axios.js";

const api = {};

api.fetchNotifications = function (userID, permission, skip) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/notification/get/${userID}/${permission}?skip=${skip}`;
  return axios.get(url);
};

api.seenSingleNotification = function (id, userID) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/notification/seen/${id}/${userID}`;
  return axios.get(url);
};

api.seenAllNotification = function (userID, body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/notification/seen-all/${userID}`;
  return axios.post(url, body);
};

api.register = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/register`;

  return axios.post(url, body);
};

api.login = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/login`;

  return axios.post(url, body);
};

api.edit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/edit`;

  return axios.patch(url, body);
};

api.getById = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/get/by-id`;

  return axios.post(url, body);
};

api.logout = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/logout`;

  return axios.post(url, body);
};

api.checkSession = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/check/session`;

  return axios.post(url, body);
};

api.uploadFile = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;

  return axios.post(url, body);
};

api.discordAuth = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/discord-auth`;

  return axios.post(url, body);
};

api.createPayment = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/user/create/new`;

  return axios.post(url, body);
};

api.getAllSessions = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/get/all-session`;

  return axios.post(url, body);
};

api.getAllUsers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/get/all`;

  return axios.post(url, body);
};

api.editPayment = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/user/edit`;

  return axios.post(url, body);
};

api.getInactivePay = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/get/in-active`;

  return axios.post(url, body);
};

api.getBasicPay = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/get/basic`;

  return axios.post(url, body);
};

api.getPremiumPay = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/get/premium`;

  return axios.post(url, body);
};

api.createLive = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/live/create/new`;

  return axios.post(url, body);
};

api.getLive = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/live/get`;

  return axios.post(url, body);
};

api.editLive = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/live/edit`;

  return axios.patch(url, body);
};

api.joinDiscordGroup = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/connect/add/to-guild`;

  return axios.patch(url, body);
};

api.removeFromDiscord = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/remove/from-guild`;

  return axios.delete(url, body);
};

api.createPaymentHistory = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/create/new`;

  return axios.post(url, body);
};

api.editLast = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/edit/last`;

  return axios.patch(url, body);
};

api.getAllPay = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/all`;

  return axios.post(url, body);
};

api.getAllPayUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/all/user`;

  return axios.post(url, body);
};

api.getTotalAmount = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/amount`;

  return axios.post(url, body);
};

api.getTotalAmount_month = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/amount/month`;

  return axios.post(url, body);
};

api.getTotalAmount_week = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/amount/week`;

  return axios.post(url, body);
};

api.getTotalAmount_day = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/history/get/amount/day`;

  return axios.post(url, body);
};

api.createSubscription = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscription/create/new`;

  return axios.post(url, body);
};

api.deleteSubscription = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscription/delete`;

  return axios.delete(url, body);
};

api.getSubscription = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscription/get`;

  return axios.delete(url, body);
};

api.editSubs = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/subscription/edit`;

  return axios.post(url, body);
};

api.createStripeSubscription = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/stripe/create`;

  return axios.post(url, body);
};

api.createStripeOneTimePay = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/stripe/create/onetime`;

  return axios.post(url, body);
};

api.generate2FA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/2fa/generate`;

  return axios.post(url, body);
};

api.verify2FA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/2fa/verfiy`;

  return axios.post(url, body);
};

api.verify2FACode = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/2fa/verfiy-code`;

  return axios.post(url, body);
};

api.checkLoginSessionWithoutFA = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/check/session/login`;

  return axios.post(url, body);
};

api.verifyEmail = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/verfiy-email`;

  return axios.post(url, body);
};

api.sendEmail = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/send/otp/ev`;

  return axios.post(url, body);
};

api.sendEmailForgetPass = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/send/forget-pass`;

  return axios.post(url, body);
};

api.sendEmailIssue = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/send/issue`;

  return axios.post(url, body);
};

api.getAllAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/get/all/admin`;

  return axios.post(url, body);
};

api.removeAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/remove/admin`;

  return axios.post(url, body);
};

api.signalCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/new`;

  return axios.post(url, body);
};

api.signalEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/edit`;

  return axios.patch(url, body);
};

api.signalDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/delete`;

  return axios.delete(url, body);
};

api.signalGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/get`;

  return axios.post(url, body);
};

api.CryptoUpdateCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-update/new`;

  return axios.post(url, body);
};

api.CryptoUpdateEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-update/edit`;

  return axios.patch(url, body);
};

api.CryptoUpdateDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-update/delete`;

  return axios.delete(url, body);
};

api.CryptoUpdateGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-update/get`;

  return axios.post(url, body);
};

api.createGuide = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/guide/new`;

  return axios.post(url, body);
};

api.getGuide = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/guide/get`;

  return axios.post(url, body);
};

api.editGuide = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/guide/edit`;

  return axios.patch(url, body);
};

api.deleteGuide = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/guide/delete`;

  return axios.delete(url, body);
};

api.guideCategory = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/guide/category`;

  return axios.post(url, body);
};

api.createIssue = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issue/raise`;

  return axios.post(url, body);
};

api.getIssues = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issue/get-all`;

  return axios.post(url, body);
};

api.getIssuesByUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issue/get-by-user`;

  return axios.post(url, body);
};

api.editIssue = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issue/edit`;

  return axios.patch(url, body);
};

api.reactCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/react/create`;

  return axios.post(url, body);
};

api.getReactionSignal = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/react/get/user`;

  return axios.post(url, body);
};

api.deletereactionOfSignal = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/react/delete`;

  return axios.delete(url, body);
};

api.getAllReactions = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/signal/react/get`;

  return axios.post(url, body);
};

api.reactCreateCrypto = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-updates/react/create`;

  return axios.post(url, body);
};

api.getReactionCrypto = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-updates/react/get/user`;

  return axios.post(url, body);
};

api.deletereactionOfCrypto = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-updates/react/delete`;

  return axios.delete(url, body);
};

api.getAllReactionsCrypto = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/crypto-updates/react/get`;

  return axios.post(url, body);
};

api.getExportDataUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/get-exportable`;

  return axios.post(url, body);
};

api.createIssuesReply = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issues/reply/create`;

  return axios.post(url, body);
};

api.getIssuesReply = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/issues/reply/get`;

  return axios.post(url, body);
};

export default api;
